import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import _isNumber from 'lodash-es/isNumber';

@Pipe({
  name: 'priceRange',
  pure: true,
  standalone: true,
})
export class PriceRangeStandalonePipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(prices: number[], digitsInfo = '1.0-2'): string {
    const priceMin = prices[0];
    const priceMax = prices[1];
    const quantityMin = prices[2];
    const quantityMax = prices[3];

    const priceMinFormatted = this._formatPrice(
      priceMin,
      quantityMin,
      digitsInfo
    );
    const priceMaxFormatted = this._formatPrice(
      priceMax,
      quantityMax,
      digitsInfo
    );

    let formattedPrice = '';

    if (priceMinFormatted.length > 0) {
      formattedPrice += priceMinFormatted;
    }

    if (
      priceMaxFormatted.length > 0 &&
      priceMaxFormatted !== priceMinFormatted
    ) {
      formattedPrice += formattedPrice ? ' - ' : '';
      formattedPrice += priceMaxFormatted;
    }

    return formattedPrice;
  }

  private _formatPrice(price, quantity, digitsInfo: string = '1.0-2') {
    let formattedPrice = '';

    if (_isNumber(price)) {
      formattedPrice += '$' + this._round(price, digitsInfo);

      if (_isNumber(quantity)) {
        formattedPrice += ' /' + quantity;
      }
    }

    return formattedPrice;
  }

  private _round(price: number, digitsInfo: string = '1.0-2'): string {
    return this.decimalPipe.transform(price, digitsInfo);
  }
}
